<template>
  <v-app>
    <core-drawer />

    <v-content class="overflow-hidden">
      <router-view />
    </v-content>
    <v-row>
      <v-col
        cols="12"
        style="text-align: center;"
      >
        <small>Tested by <a
          class="text-decoration-none"
          href="https://calabratix.com"
        >Calabratix</a></small>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
  export default {
    components: {
      CoreDrawer: () => import('@/components/core/Drawer'),
    },
  }
</script>

<style>
  .text-decoration-none {
    text-decoration: none;
  }
</style>
